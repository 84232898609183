.modal-content {

  background-color: transparent;
  border: 0;
  max-height: 100%;
  max-width: 100%;

  img {
    display: block;
    margin: auto;
    max-width: 100%;
  }
}

.modal-dialog {
  max-width: 100% !important;
}

.modal-backdrop.show {
  opacity: 0.9;
}

.modal a.close {
  display: block;
  width: 48px;
  height: 48px;
  margin-right: 25px;
  margin-left: auto;
  opacity: 1;

  svg {
    fill: #fff;
  }

  &:hover {
    svg {
      fill: #bf2249;
    }
  }
}
