.back.close {
  position: absolute;
  top: 50px !important;
  right: 50px !important;
  display: block;
  width: 48px;
  height: 48px;
  transform: none;
  opacity: 1;

  &:hover {
    transform: scale(1.2) !important;
  }

  svg {
    fill: #fff;
  }
}

.gallery {
  position: absolute;
  top: 59%;
  left: 50%;
  display: flex;
  flex-flow: row-reverse wrap;
  width: 910px;
  overflow-y: visible;
  overflow-x: hidden;
  transform: translate(-50%, -50%) rotate(-90deg);
  padding: 25px 50px;

  div {
    flex: 1 400px;
    display: block;
    width: 400px;
    height: 400px;
    transform: rotate(90deg);
    max-width: 400px;
  }

  img {
    transition: 0.2s all;
    cursor: pointer;
    pointer-events: all;

    &:hover {
      transform: scale(1.1);
    }
  }
}

.gallery-outer {
  overflow-y: hidden;
}

#portfolio-title {
  position: absolute;
  top: 50px;
  left: 50px;
  font-family: 'Oswald', sans-serif;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 25px;
}

@media(max-height: 1100px) {
  .gallery {
    transform: translate(-50%, -50%) rotate(-90deg) scale(0.8);
  }
}

@media(max-height: 900px) {
  .gallery {
    transform: translate(-50%, -50%) rotate(-90deg) scale(0.6);
  }
}

@media(max-width: 700px) {
  #back-arrow {
    display: none;
  }

  .back.close {
    top: -10px !important;
    right: 25px !important;
  }

  #portfolio-title {
    position: static;
    margin: 25px;
  }

  .gallery {
    flex-flow: row wrap;
    position: static;
    width: 100%;
    transform: none;
    padding: 0px;

    div {
      position: relative;
      flex: 1 100%;
      transform: none;
      text-align: center;
      margin: 25px 0px;
      height: auto;

      img {
        transform: none;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}

@media(max-width: 500px) {
  #portfolio-title {
    letter-spacing: 10px;
  }
}

.photo-outer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.8);
  z-index: 10000;

  .close {
    position: fixed;
    right: 25px;
    top: 25px;
    width: 48px;
    height: 48px;
    opacity: 1;
    transition: 0.2s all;

    svg {
      fill: #fff;
    }

    &:hover {
      transform: scale(1.2) !important;

      svg {
        fill: #bf2249;
      }
    }
  }
}

.photo {
  position: fixed;
  top: 75px;
  left: 75px;
  right: 75px;
  overflow: auto;
  height: calc(100vh - 75px);
  max-width: 100%;

  img {
    display: block;
    margin: auto;
    max-width: 100%;
  }
}

@media(max-width: 700px) {
  .photo {
    left: 0px;
    right: 0px;
  }

  .photo-outer {
    background-color: #000;

    .close {
      top: 15px;
      right: 15px;
    }
  }
}
