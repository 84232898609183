@import url('https://fonts.googleapis.com/css?family=Oswald:200,300');

$body-bg: #191919;

@import "node_modules/bootstrap/scss/bootstrap";

@import "./contact.scss";
@import "./gallery.scss";
@import "./modal.scss";

html,
body {
  max-width: 100% !important;
  overflow-x: hidden !important;
}

body {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
}

img {
    pointer-events: none;
}

footer {
  display: flex;
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-align: center;
  padding: 25px;
  line-height: 68px;

  div:first-child {
    text-align: right;
  }

  div:last-child {
    text-align: left;
  }

  p {
    color: #fff;
    text-transform: uppercase;
  }

  img {
    width: auto !important;
    max-width: 100% !important;
  }
}

#fp-nav ul li .fp-tooltip {
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  font-weight: 200;
  margin-top: -3px;
}

div.section#logo {
  background-image: url(/assets/images/background/main.png);
  background-size: cover;

  svg {
    display: block;
    margin: auto;
    width: 250px;

    path {
      fill: transparent;
      stroke: #fff;
      stroke-dasharray: 12000;
      stroke-dashoffset: 12000;
      -webkit-animation: logo-line 3s ease .2s alternate forwards, logo-color 2s ease-in-out 3.3s alternate forwards;
      animation: logo-line 3s ease .2s alternate forwards, logo-color 2s ease-in-out 3.3s alternate forwards;
      stroke-width: 4
    }
  }
}

div.section {
  font-family: 'Oswald', sans-serif;

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &.bottom {
      bottom: 0px;
      height: 50%;
      transform: translateX(-50%);
    }
  }

  img {
    transition: 0.5s all;
  }

  .desc {
    color: #fff;
    line-height: 1.5;

    &.absolute {
      position: absolute;
    }

    h1 {
      font-weight: 200;
      text-transform: uppercase;
      letter-spacing: 20px;
    }

    p {
      font-weight: 200;
      text-transform: uppercase;
    }
  }
}

div[data-anchor="branding"] > div {
  transform: translateY(60px);
}

div[data-anchor="fotografia"] img {
  transform: translateX(-35%);
}

div[data-anchor="bluzy"] .content > img {
  transform: translateX(-200px);
}

#scroll {
  position: absolute;
  width: auto;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%) !important;
  animation: scroll-animation 1s ease-in-out infinite;
}

#visual-identity-text {
  top: -50px;
  left: -121px;
  transition: 0.5s all;
}

#branding-text {
  bottom: 250px;
  right: -280px;
  text-align: right;
  max-width: 450px;
  transition: 0.5s all;
}

#motto-text {
  position: absolute;
  top: 50%;
  left: calc(50% + 350px);
  transform: translateY(-50%);
  color: #fff;
  font-size: 24px;
  text-transform: uppercase;
  transition: 0.5s all;
  opacity: 0;
  animation: motto-animation 2s ease-in-out 3.5s alternate forwards;
  line-height: 1.1;
}

#fotografia-text {
  top: 200px;
  right: -350px;
  max-width: 600px;
  transition: 0.5s all;
}

#styl-text {
  top: 200px;
  right: -300px;
  max-width: 500px;
  text-align: center;
  transition: 0.5s all;
}

#photographer-arrow {
  top: calc(50% - 50px);
  left: calc(50% - 250px);
  opacity: 0;
  animation: arrow-fade 2s ease-in-out 3.5s alternate forwards;

  p {
    position: absolute;
    top: -25px;
    left: -20px;
    transform: rotate(5deg);
  }
}

#clothing-arrow {
  top: calc(50% + 100px);
  left: calc(50% - 225px);
  opacity: 0;
  animation: arrow-fade 2s ease-in-out 3.5s alternate forwards;

  img {
    transform: scaleX(-1) rotate(30deg);
  }

  p {
    position: absolute;
    top: -15px;
    left: 0px;
    transform: rotate(-5deg);
    text-align: center;
  }
}

#designer-arrow {
  top: calc(50% - 100px);
  left: calc(50% + 175px);
  opacity: 0;
  animation: arrow-fade 2s ease-in-out 3.5s alternate forwards;

  img {
    transform: rotate(-10deg);
  }

  p {
    position: absolute;
    top: -25px;
    left: 5px;
    transform: rotate(2deg);
    text-align: center;
  }
}

#projekty-arrow {
  top: 50px;
  right: 100px;

  img {
    transform: rotate(-10deg);
  }

  p {
    position: absolute;
    top: -35px;
    left: -30px;
    transform: rotate(-5deg);
    width: 200px;
  }
}

#realizacje-arrow {
  bottom: 100px;
  left: 125px;

  img {
    transform: rotate(-40deg);
  }

  p {
    position: absolute;
    top: 20px;
    left: -70px;
    transform: rotate(-20deg);
  }
}

#pisz-arrow {
  bottom: 110px;
  right: -200px;

  img {
    transform: rotate(-5deg);
  }

  p {
    position: absolute;
    bottom: 30px;
    right: -35px;
    transform: rotate(-5deg);
  }
}

#portfolio-arrow,
#fotografia-arrow {
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: block;
  width: 48px;
  transition: 0.2s all;

  &:hover {
    transform: translate(5px, -50%);

    svg {
      fill: #bf2249;
    }
  }

  svg {
    fill: #fff;
  }
}

#sprawdz-arrow {
  position: absolute;
  top: calc(50% - 200px);
  right: 50px;
  transform: translateY(-50%);

  img {
    transform: rotate(-100deg);
  }

  p {
    position: absolute;
    top: -60px;
    left: -30px;
    transform: rotate(-10deg);
    width: 150px;
  }
}

#ogarnij-arrow {
  position: absolute;
  bottom: calc(50% - 150px);
  right: 100px;
  transform: translateY(-50%);

  img {
    transform: rotate(-40deg);
  }

  p {
    position: absolute;
    top: -20px;
    left: -60px;
    transform: rotate(-20deg);
    width: 150px;
    text-align: center;
  }
}

#plener-arrow {
  top: 100px;
  right: 250px;

  img {
    transform: rotate(-40deg);
  }

  p {
    position: absolute;
    top: -85px;
    left: 21px;
    transform: rotate(-5deg);
    width: 200px;
  }
}

#rekwizyty-arrow {
  bottom: 91px;
  right: 225px;

  img {
    transform: rotate(-180deg);
  }

  p {
    position: absolute;
    top: 5px;
    left: 57px;
    transform: rotate(12deg);
    width: 300px;
  }
}

#modele-arrow {
  bottom: 300px;
  left: -275px;

  img {
    transform: rotate(-35deg);
  }

  p {
    position: absolute;
    top: -25px;
    left: -130px;
    transform: rotate(-27deg);
    width: 300px;
    text-align: center;
  }
}

#napisz-arrow {
  bottom: 238px;
  right: 80px;

  img {
    transform: rotate(-2deg) scaleX(-1);
  }

  p {
    position: absolute;
    top: -30px;
    left: 20px;
    transform: rotate(-5deg);
    width: 200px;
  }
}

#metki-arrow {
  top: 288px;
  left: -156px;

  img {
    transform: rotate(30deg) scaleX(-1);
  }

  p {
    position: absolute;
    top: -55px;
    left: -120px;
    transform: rotate(12deg);
    width: 300px;
    text-align: center;
  }
}

#kolor-arrow {
  top: 418px;
  left: -189px;

  img {
    transform: rotate(5deg);
  }

  p {
    position: absolute;
    top: -35px;
    left: -98px;
    transform: rotate(7deg);
    width: 300px;
    text-align: center;
  }
}

#logo-arrow {
  bottom: 420px;
  left: 430px;

  img {
    transform: rotate(200deg);
  }

  p {
    position: relative;
    top: -35px;
    left: 50px;
    text-align: center;
    transform: rotate(13deg);
  }
}

#back-arrow {
  position: absolute;
  top: 50px;
  right: 125px;

  img {
    transform: rotate(-170deg);
  }

  p {
    position: absolute;
    top: 35px;
    left: -70px;
    width: 150px;
  }
}

#instagram-arrow {
  position: absolute;
  bottom: 285px;
  right: 50px;

  img {
    transform: rotate(5deg);
  }

  p {
    position: absolute;
    top: -45px;
    left: -80px;
    width: 150px;
    transform: rotate(5deg);
    text-align: center;
  }
}

#facebook-arrow {
  position: absolute;
  bottom: 275px;
  right: -250px;

  img {
    transform: rotate(5deg);
  }

  p {
    position: absolute;
    top: -35px;
    left: 30px;
    width: 150px;
    transform: rotate(5deg);
    text-align: center;
  }
}

#portfolio-daria-arrow {
  top: -40px;
  left: 120px;

  img {
    transform: rotate(210deg);
  }

  p {
    position: absolute;
    top: 20px;
    left: -135px;
    transform: rotate(-5deg);
    width: 200px;
  }
}

#portfolio-arek-arrow {
  top: 10px;
  right: -60px;

  img {
    transform: rotate(180deg) scaleX(-1);
  }

  p {
    position: absolute;
    top: 55px;
    left: 25px;
    transform: rotate(-5deg);
    width: 200px;
  }
}

@media(max-width: 2075px) {
  #portfolio-daria-arrow {
    left: -30px;
  }

  #portfolio-arek-arrow {
    right: -80px;
  }
}

@media(max-width: 1350px) {
  #portfolio-daria-arrow,
  #portfolio-arek-arrow {
    display: none;
  }
}

a#contact {
  position: absolute;
  bottom: 100px;
  right: -100px;
}

#portfolio-mobile,
#fotografia-mobile {
  display: none;
}

#umow {
  position: absolute;
  bottom: 250px;
  right: -100px;
}

#dostepne {
  position: absolute;
  width: 450px;
  bottom: 150px;
  right: -275px;
  transition: 0.5s all;

  .btn {
    display: block;
    width: 150px;
    margin: auto;
  }

  .col-6:first-child {
    text-align: right;
  }

  img {
    display: block;
    margin: 25px auto;
  }
}

@media(max-width: 1750px) {
  .section .content > img {
    transform: scale(0.75);
  }

  div[data-anchor="fotografia"] .content > img {
    transform: translateX(-35%) scale(0.75) !important;
  }

  div[data-anchor="bluzy"] .content > img {
    transform: translateX(-200px) scale(0.75);
  }

  #visual-identity-text {
    left: 0px;
  }

  #branding-text {
    right: -150px;
  }

  #fotografia-text {
    right: -250px;
  }

  #styl-text {
    right: -200px;
  }

  #projekty-arrow {
    top: 100px;
    right: 250px;
  }

  #realizacje-arrow {
    bottom: 150px;
    left: 200px;
  }

  #pisz-arrow {
    right: -70px;
  }

  #plener-arrow {
    top: 150px;
    right: 350px;
  }

  #rekwizyty-arrow {
    bottom: 125px;
    right: 300px;
  }

  #modele-arrow {
    bottom: 350px;
    left: -200px;

    p {
      top: -25px;
      left: -130px;
    }
  }

  #napisz-arrow {
    bottom: 238px;
    right: 230px;
  }

  #metki-arrow {
    left: -75px;
  }

  #kolor-arrow {
    left: -125px;
  }

  #logo-arrow {
    bottom: 420px;
    left: 400px;
  }

  a#contact {
    right: 30px;
  }

  #umow {
    right: 50px;
  }

  #dostepne {
    right: -175px;

    img {
      transform: none;
    }
  }

  #instagram-arrow {
    bottom: 285px;
    right: 150px;
  }

  #facebook-arrow {
    bottom: 275px;
    right: -150px;
  }
}

@media(max-width: 1500px), (max-height: 700px) {
  .section .content > img {
    transform: scale(0.5);
  }

  div[data-anchor="fotografia"] .content > img {
    transform: translateX(-35%) scale(0.5) !important;
  }

  div[data-anchor="bluzy"] .content > img {
    transform: translateX(-200px) scale(0.5);
  }

  #motto-text {
    left: calc(50% + 250px);
  }

  #visual-identity-text {
    left: 150px;
  }

  #branding-text {
    right: 0px;
    max-width: 350px;
  }

  #fotografia-text {
    right: -125px;
  }

  #styl-text {
    right: -125px;
  }

  #projekty-arrow {
    top: 150px;
    right: 350px;
  }

  #realizacje-arrow {
    bottom: 200px;
    left: 300px;
  }

  #pisz-arrow {
    right: 0px;
  }

  #plener-arrow {
    top: 200px;
    right: 500px;
  }

  #rekwizyty-arrow {
    bottom: 175px;
    right: 500px;
    transform: rotate(15deg);
  }

  #modele-arrow {
    bottom: 325px;
    left: -100px;
    transform: rotate(-20deg);

    p {
      top: -25px;
      left: -125px;
    }
  }

  #napisz-arrow {
    bottom: 288px;
    right: 400px;
  }

  #metki-arrow {
    top: 400px;
    left: -25px;
  }

  #kolor-arrow {
    top: 475px;
    left: -100px;
  }

  #logo-arrow {
    bottom: 430px;
    left: 320px;
  }

  a#contact {
    right: 100px;
  }

  #umow {
    bottom: 300px;
    right: 225px;
  }

  #dostepne {
    right: -75px;

    img {
      transform: none;
    }
  }

  #instagram-arrow {
    bottom: 285px;
    right: 250px;
  }

  #facebook-arrow {
    bottom: 275px;
    right: -50px;
  }
}

@media(max-height: 800px) {
  div.section > div {
    transform: scale(0.75);
  }

  div[data-anchor="branding"] > div {
    transform: translateY(60px) scale(0.75);
  }

  #team {
    height: calc(100% - 50px);
    width: 100%;
    transform: none !important;
  }

  #team,
  footer {
    position: static;
    padding: 0px;

    div {
      text-align: center !important;
    }

    a.instagram,
    a.facebook {
      margin: 0px 10px;
    }
  }
}

@media (max-width: 1200px) {
  div.section {
    .content {
      position: static;
      transform: none !important;
    }

    img {
      width: 100%;
      transform: none !important;
    }

    .arrow {
      display: none;
    }

    & > div {
      transform: none;
    }
  }

  br {
    display: none;
  }

  .arrow > p > br {
    display: block;
  }

  div[data-anchor="fotografia"] .content > img {
    display: block !important;
    width: 75% !important;
    margin: auto !important;
    transform: none !important;
  }

  #logo svg {
    position: static;
  }

  #motto-text {
    position: static;
    text-align: center;
    width: 100%;
    transform: none;
    margin: 25px 0px;

    br {
      display: block;
    }
  }

  #scroll {
    position: relative;
    display: block;
    margin: 25px 0px;
    transform: translate(-50%, 25px) !important;
  }

  #visual-identity-text,
  #branding-text,
  #fotografia-text,
  #styl-text {
    position: static !important;
    margin: 15px;
    text-align: center;
    max-width: none;
    font-size: 13px;
  }

  a#contact,
  #umow,
  #portfolio-mobile,
  #fotografia-mobile {
    position: static;
    display: block;
    width: 250px;
    margin: 15px auto;
    font-size: 24px;
  }

  #portfolio-mobile {
    width: 350px;
  }

  #fotografia-mobile {
    width: 250px;
  }

  #portfolio-arrow,
  #fotografia-arrow {
    display: none;
  }

  .section[data-anchor="bluzy"] img {
    display: block;
    margin: auto;
    width: 100%;
    max-width: 600px;
  }

  #dostepne {
    position: static;
    display: block;
    margin: 15px auto;
    width: auto;

    img {
      width: auto;
      max-width: 400px;
      transform: none !important;
    }
  }

  #team {
    transform: none;
    height: calc(100% - 50px) !important;
  }

  #team,
  footer {
    position: static;
    padding: 0px;

    div {
      text-align: center !important;
    }

    a.instagram,
    a.facebook {
      margin: 0px 10px;
    }
  }

  footer {
    line-height: 36px;

    p {
      margin-top: 4px;
    }
  }
}

div.arrow {
  position: absolute;
  font-family: 'Oswald', sans-serif;
  color: #fff;
  font-weight: 200;
  text-transform: uppercase;
  transition: 0.5s all;
  line-height: 1.1;
}

@-webkit-keyframes logo-line {
  from {
    stroke-dashoffset: 12000
  }
  to {
    stroke-dashoffset: 0
  }
}

@keyframes logo-line {
  from {
    stroke-dashoffset: 12000
  }
  to {
    stroke-dashoffset: 0
  }
}

@-webkit-keyframes logo-color {
  from {
    fill: transparent
  }
  to {
    fill: #fff
  }
}

@keyframes logo-color {
  from {
    fill: transparent
  }
  to {
    fill: #fff
  }
}

@keyframes arrow-fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes motto-animation {
  from {
    opacity: 0;
    margin-left: 25px;
  }

  to {
    opacity: 1;
    margin-left: 0px;
  }
}

@keyframes scroll-animation {
  0% {
    bottom: 25px;
  }
  50% {
    bottom: 35px;
  }
  100% {
    bottom: 25px;
  }
}

.btn {
  text-transform: uppercase;
  border: 2px solid transparent;
  border-radius: 0;

  &.btn-default {
    color: #fff;
    border-color: #fff;
    transition: 0.5s all;
    background-color: transparent;

    &:hover {
      color: #000;
      background-color: #fff;
    }
  }
}

a.instagram {
  margin: 0px 35px;
  color: #fff;
  font-size: 32px;
  transition: 0.2s all;
  text-decoration: none;

  &:hover {
    color: #cd486b;
  }
}

a.facebook {
  margin: 0px 35px;
  color: #fff;
  font-size: 32px;
  transition: 0.2s all;
  text-decoration: none;

  &:hover {
    color: #3b5998;
  }
}

#team {
  min-width: 60vw;
  transform: translate(-50%, -50%);
  height: auto;
  bottom: initial !important;

  h1,
  h2,
  h3 {
    font-family: 'Oswald', sans-serif;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
  }

  h1 {
    padding-left: 15px;
    letter-spacing: 15px;

    a {
      color: #ffffff;

      &:hover {
        color: #bf2249;
        text-decoration: none;
      }
    }
  }

  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 15px;
  }
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #191919;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #333;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #333;
}
