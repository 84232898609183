div#contact {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Oswald', sans-serif;
  color: #fff;

  h1 {
    letter-spacing: 15px;
    text-transform: uppercase;
    text-align: center;
  }

  h2 {
    text-transform: uppercase;
  }

  small {
    font-weight: 200;
    font-size: 14px;
    text-transform: uppercase;
  }

  svg {
    display: block;
    color: #bf2249;
    font-size: 64px;
    margin: 15px auto;
  }
}

.form-group:nth-child(4) {
  margin-bottom: 0;
}

.form-control {
  background-color: transparent;
  border: 2px solid #fff;
  border-radius: 0;
  color: #fff !important;

  &:focus {
    background-color: transparent;
  }

  &::placeholder {
    text-transform: uppercase;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    text-transform: uppercase;
  }

  &::-ms-input-placeholder {
    text-transform: uppercase;
  }
}

#mail-arrow {
  top: 116px;
  right: -100px;

  img {
    transform: rotate(5deg);
  }

  p {
    position: absolute;
    top: -40px;
    left: -75px;
    transform: rotate(7deg);
    width: 300px;
    text-align: center;
  }
}

#telephone-arrow {
  top: 175px;
  left: -125px;

  img {
    transform: rotate(5deg);
  }

  p {
    position: absolute;
    top: -45px;
    left: -120px;
    transform: rotate(7deg);
    width: 300px;
    text-align: center;
  }
}

#napisz-mail-arrow {
  bottom: -25px;
  right: calc(50% - 150px);
  transition: 0.5s all;

  img {
    transform: rotate(30deg);
    transition: 0.5s all;
  }

  p {
    position: absolute;
    top: -25px;
    left: -30px;
    transform: rotate(-7deg);
    width: 300px;
    text-align: center;
  }
}

a.back {
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: block;
  width: 48px;
  transition: 0.2s all;
  cursor: pointer;

  .full {
    fill: #fff;
  }

  .mobile {
    display: none;
  }

  &:hover {
    transform: translate(5px, -50%);

    svg {
      fill: #bf2249;
    }
  }
}

@media(max-width: 700px) {
  #contact {
    position: static !important;
    transform: none !important;
    padding: 15px;
  }

  #mail-arrow,
  #telephone-arrow {
    display: none;
  }

  #napisz-mail-arrow {
    display: none;
    bottom: -75px;
    right: calc(50% - 45px);

    img {
      transform: rotate(75deg);
    }

    p {
      top: 35px;
      left: 0px;
    }
  }

  a.back {
    position: static;
    float: right;
    margin-left: auto;
    margin-right: 0px;
    margin: 35px 10px 25px auto;
    display: block;
    width: 48px;
    height: 48px;
    transition: 0.2s all;

    .full {
      display: none;
    }

    .mobile {
      display: block;
      fill: #fff;
    }

    &:hover {
      transform: scale(1.2);
    }
  }
}
